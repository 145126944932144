import React from "react";
import Container from "react-bootstrap/Container";

import "./styles.css";

import Hero from "../../components/hero";

import saida from "./resume.js";
// import en from "./resume.en";

export default function resume(props) {
  // console.log("files: ", saida.br, saida.en);
  return (
    <Container fluid={true}>
      <Hero title={props.title} subTitle={props.subTitle} text={saida.br} />
    </Container>
  );
}
