import React from "react";
import "./styles.css";

export default function about() {
  return (
    <div className="home">
      <h3>About</h3>
    </div>
  );
}
