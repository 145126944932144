import React, { Component } from "react";
import "./styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

class contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      phone: "",
      company: "",
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };
  handleInputChange = (event) => {
    this.setState = {
      [event.target.name]: event.target.value,
    };
  };

  render() {
    return (
      <Container className="p-0" fluid={true}>
        <form className="">
          <div className="contact-form border p-0">
            <div className="contact-left">
              <Row className="">Contact-me</Row>
              <Row>
                {/* <label className="p-1" htmlFor="name">
                  Nome
                </label> */}
                <input
                  className="contact-input"
                  name="name"
                  defaultValue={this.state.name}
                  onChange={this.handleInputChange}
                  placeholder="Name"
                />
              </Row>
              <Row>
                {/* <label className="p-1" htmlFor="name">
                  Nome
                </label> */}
                <input
                  className="contact-input"
                  name="email"
                  type="email"
                  defaultValue={this.state.email}
                  onChange={this.handleInputChange}
                  placeholder="E-mail"
                />
              </Row>
              <Row>
                {/* <label className="p-1" htmlFor="name">
                  Nome
                </label> */}
                <input
                  className="contact-input"
                  name="phone"
                  type="tel"
                  defaultValue={this.state.phone}
                  onChange={this.handleInputChange}
                  placeholder="Phone"
                />
              </Row>
              <Row>
                {/* <label className="p-1" htmlFor="name">
                  Nome
                </label> */}
                <input
                  className="contact-input"
                  name="company"
                  defaultValue={this.state.message}
                  onChange={this.handleInputChange}
                  placeholder="Company"
                />
              </Row>
            </div>
            <div className="contact-right">
              <Row className=""></Row>
              <Row>
                <textarea
                  name="message"
                  className="contact-input mt-4"
                  id="message"
                  defaultValue={this.state.message}
                  onChange={this.handleInputChange}
                  placeholder="Message"
                />
              </Row>
              <Row>
                <Button variant="outline-success">Enviar</Button>
              </Row>
            </div>
          </div>
        </form>
      </Container>
    );
  }
}

export default contact;
