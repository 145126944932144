import React from "react";
import "./styles.css";
import Construction from "../../assets/images/construction.jpg";
import Container from "react-bootstrap/Container";

export default function projects() {
  return (
    <Container className="projects" fluid={true}>
      <p>
        <h3>Under Construction</h3>
      </p>
      <img src={Construction} alt="Under Construction" />
    </Container>
  );
}
